<template>
  <vue-page-transition name="fade">
    <div v-if="user" id="profile">
      <div class="side-bar">
        <div class="body">
          <div class="name">
            <h1>{{ user.displayName }}</h1>
            <span
              role="button"
              class="edit"
              :class="{ active: editing }"
              @click="editing = !editing"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 24 24"
              >
                <path
                  d="M18.4,4.4l1.2,1.2L6.2,19H5v-1.2L18.4,4.4 M18.4,2c-0.3,0-0.5,0.1-0.7,0.3L3,17v4h4L21.7,6.3c0.4-0.4,0.4-1,0-1.4l-2.6-2.6 C18.9,2.1,18.7,2,18.4,2L18.4,2z"
                />
                <path
                  d="M15.8 4.3H17.8V9.2H15.8z"
                  transform="rotate(-45.001 16.75 6.75)"
                />
              </svg>
            </span>
          </div>
          <form
            class="contact-info"
            :class="{ editing }"
            @submit.prevent="updateUserAndCloseEditing"
          >
            <input
              type="email"
              v-model="email"
              :disabled="!editing"
              id="email"
              required
            />
            <input
              type="tel"
              v-model="phoneNumber"
              :disabled="!editing"
              id="tel"
              required
            />
            <p v-if="editing && !phoneNumberValid" class="invalidPhoneNumber">
              Your phone number must begin with a country code
            </p>

            <button
              v-if="editing"
              type="submit"
              class="btn"
              :class="{ loading }"
            >
              <template v-if="!loading">Save changes</template>
            </button>
          </form>

          <template class="action-area">
            <ul v-if="!isAdmin" class="profile-actions">
              <li role="button" class="profile-action" @click="logOut">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  viewBox="0 0 24 24"
                  fill="#C6C6C6"
                >
                  <path
                    d="M12 3A9 9 0 1 0 12 21A9 9 0 1 0 12 3Z"
                    opacity=".3"
                  />
                  <path d="M8 11H14V13H8zM8 16L4 12 8 8z" />
                  <path
                    d="M12,22C6.5,22,2,17.5,2,12C2,6.5,6.5,2,12,2c5.5,0,10,4.5,10,10C22,17.5,17.5,22,12,22z M12,4c-4.4,0-8,3.6-8,8 c0,4.4,3.6,8,8,8c4.4,0,8-3.6,8-8C20,7.6,16.4,4,12,4z"
                  />
                </svg>
                Log Out
              </li>
              <li
                role="button"
                class="profile-action danger"
                @click="deleteUser"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  viewBox="0 0 24 24"
                  fill="#C6C6C6"
                >
                  <path d="M3 3H21V5H3z" />
                  <path
                    d="M16.1,22H7.9c-1,0-1.9-0.7-2-1.7L4,4.1l2-0.2L7.9,20l8.2,0L18,3.9l2,0.2l-1.9,16.1C18,21.3,17.1,22,16.1,22z"
                  />
                  <path
                    d="M5,4l1.9,16.1c0.1,0.5,0.5,0.9,1,0.9h8.2 c0.5,0,0.9-0.4,1-0.9L19,4H5z"
                    opacity=".3"
                  />
                  <path d="M15 3L15 4 9 4 9 3 10 2 14 2z" />
                </svg>
                Delete User
              </li>
            </ul>
            <ul v-else class="profile-actions">
              <li>
                <router-link
                  :to="{ name: 'ManagePlays' }"
                  class="profile-action"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M19,20c0,0.6-0.4,1-1,1H6c-0.6,0-1-0.4-1-1V4c0-0.6,0.4-1,1-1h7.6L19,8.4V20z"
                      opacity=".3"
                    />
                    <path d="M8 12H16V14H8zM8 16H16V18H8z" />
                    <path
                      d="M18,22H6c-1.1,0-2-0.9-2-2V4c0-1.1,0.9-2,2-2h8l6,6v12C20,21.1,19.1,22,18,22z M6,4v16h12V8.8L13.2,4H6z"
                    />
                    <path d="M18.5 9L13 9 13 3.5z" />
                  </svg>
                  Manage Plays
                </router-link>
              </li>
              <li>
                <router-link
                  :to="{ name: 'UploadPlay', params: { action: 'create' } }"
                  class="profile-action"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M14,15.5V8h3l-5-5L7,8h3v7.5c0,0.3,0.2,0.5,0.5,0.5h3C13.8,16,14,15.8,14,15.5z"
                      opacity=".3"
                    />
                    <path
                      d="M2 20H22V22H2zM13.5 17h-3C9.7 17 9 16.3 9 15.5V9H4.6L12 1.6 19.4 9H15v6.5C15 16.3 14.3 17 13.5 17zM11 15h2V7h1.6L12 4.4 9.4 7H11V15z"
                    />
                  </svg>
                  Upload Plays
                </router-link>
              </li>
              <li>
                <router-link
                  :to="{ name: 'ViewMembers' }"
                  class="profile-action"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M12 13A2 2 0 1 0 12 17 2 2 0 1 0 12 13zM16 21v-1c0-1.33-2.665-2-4-2s-4 .67-4 2v1H16zM22 16v-.699c0-1.33-1.665-2.051-3-2.051s-3 .721-3 2.051V16H22zM8 16v-.699c0-1.33-1.665-2.051-3-2.051s-3 .721-3 2.051V16H8zM19 8A2 2 0 1 0 19 12 2 2 0 1 0 19 8zM15 3A2 2 0 1 0 15 7 2 2 0 1 0 15 3zM9 3A2 2 0 1 0 9 7 2 2 0 1 0 9 3zM5 8A2 2 0 1 0 5 12 2 2 0 1 0 5 8z"
                    />
                  </svg>
                  View Members
                </router-link>
              </li>
              <li>
                <router-link
                  :to="{ name: 'CreateAdmin' }"
                  class="profile-action"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M12,1L3,5c0,0,0,4,0,6c0,7.83,6.439,11.486,9,12c2.561-0.514,9-4.17,9-12c0-2,0-6,0-6L12,1z M19,11 c0,6.134-4.785,9.254-7,9.937C9.785,20.254,5,17.134,5,11V6.3l7-3.111L19,6.3V11z"
                    />
                    <path
                      d="M12 12.25c1.792 0 3.25-1.458 3.25-3.25S13.792 5.75 12 5.75 8.75 7.208 8.75 9 10.208 12.25 12 12.25zM12 7.75c.689 0 1.25.561 1.25 1.25s-.561 1.25-1.25 1.25S10.75 9.689 10.75 9 11.311 7.75 12 7.75zM6.449 17.755C7.229 17.17 8.897 16 12 16s4.771 1.17 5.551 1.755c.191-.184.376-.376.55-.58l.678-.799-.829-.641C16.398 14.538 13.537 14 12 14s-4.398.538-5.949 1.735l-.829.641L5.9 17.175C6.073 17.379 6.258 17.571 6.449 17.755z"
                    />
                    <path
                      d="M12,15c2.607,0,5.208,1.359,7.106,2.66C20.221,15.908,21,13.709,21,11c0-2,0-6,0-6l-9-4L3,5 c0,0,0,4,0,6c0,2.645,0.743,4.803,1.815,6.535C6.452,16.392,9.048,15,12,15z M12,7c1.105,0,2,0.895,2,2c0,1.105-0.895,2-2,2 s-2-0.895-2-2C10,7.895,10.895,7,12,7z"
                      opacity=".3"
                    />
                  </svg>
                  Create Admin
                </router-link>
              </li>
              <li class="profile-action danger" @click="logOut">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  viewBox="0 0 24 24"
                  fill="#C6C6C6"
                >
                  <path
                    d="M12 3A9 9 0 1 0 12 21A9 9 0 1 0 12 3Z"
                    opacity=".3"
                  />
                  <path d="M8 11H14V13H8zM8 16L4 12 8 8z" />
                  <path
                    d="M12,22C6.5,22,2,17.5,2,12C2,6.5,6.5,2,12,2c5.5,0,10,4.5,10,10C22,17.5,17.5,22,12,22z M12,4c-4.4,0-8,3.6-8,8 c0,4.4,3.6,8,8,8c4.4,0,8-3.6,8-8C20,7.6,16.4,4,12,4z"
                  />
                </svg>
                Log Out
              </li>
            </ul>
          </template>
        </div>
      </div>
      <div class="main-content">
        <router-view />
      </div>
    </div>
    <LoaderAnimation v-else />
  </vue-page-transition>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { LoaderAnimation } from "@/components";

export default {
  name: "Profile",
  data() {
    return {
      email: "",
      phoneNumber: "",
      editing: false,
      loading: false,
    };
  },
  components: {
    LoaderAnimation,
  },
  watch: {
    user(newValue) {
      if (newValue?.email) this.email = newValue?.email;
      if (newValue?.phoneNumber) this.phoneNumber = newValue?.phoneNumber;
    },
  },
  computed: {
    ...mapGetters(["user", "isAdmin"]),
    phoneNumberValid() {
      return this.phoneNumber?.charAt(0) === "+";
    },
  },
  methods: {
    ...mapActions(["logOut", "deleteUser", "updateUser"]),
    async updateUserAndCloseEditing() {
      if (!this.phoneNumberValid) return;
      const emailChanged = this.email !== this.user.email;
      const phoneNumberChanged = this.phoneNumber !== this.user.phoneNumber;
      if (emailChanged || phoneNumberChanged) {
        this.loading = true;
        try {
          await this.updateUser({
            phoneNumber: this.phoneNumber,
            email: this.email,
            displayName: this.user.displayName,
          });
          this.editing = false;
        } catch (err) {
          throw new Error(err);
        } finally {
          this.loading = false;
        }
      }
    },
  },
  async mounted() {
    if (this.user?.email) this.email = this.user?.email;
    if (this.user?.phoneNumber) this.phoneNumber = this.user?.phoneNumber;
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      setTimeout(() => {
        if (vm.$store.getters.isAuthenticated) {
          next();
        } else
          next({
            name: "SignIn",
            query: {
              name: to.name,
              params: JSON.stringify(to.params),
            },
          });
      }, 100);
    });
  },
};
</script>

<style lang="scss" scoped>
$break-point: 1024px;

#profile {
  min-height: calc(100vh - 70px);
  display: grid;
  /* grid-template-columns: 1fr; */
  /* grid-gap: 80px; */
  /* padding: 4vw; */
  color: white;

  @media (min-width: $break-point) {
    grid-template-columns: 1.2fr 4fr;
  }
}

.side-bar {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px;
  align-items: center;
  /* background: #202125; */
  padding: 50px 0;

  @media (min-width: $break-point) {
    display: block;
  }

  .profile-image {
    width: 100%;
    overflow: hidden;
    border-radius: 5px;
    padding-top: 100%;
    /* margin-bottom: 40px; */
    position: relative;

    img {
      height: 100%;
      width: 100%;
      position: absolute;
      object-fit: cover;
      top: 0;
      left: 0;
    }
  }

  .name {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 4vw;

    span {
      width: 20px;
      display: flex;
      align-items: center;
      cursor: pointer;

      svg {
        fill: $light-grey;
        transition: 0.3s all ease-out;
      }

      &:hover {
        svg {
          fill: $dark-grey;
          transform: scale(1.1);
        }
      }

      &.active {
        svg {
          fill: turquoise;
        }
      }
    }
  }

  .contact-info {
    padding: 0 4vw;
    input {
      margin-top: 10px;
      background: transparent;
      outline: none;
      border: 1px solid transparent;
      color: $dark-grey;
      width: 100%;
      transition: 0.3s all ease;

      &:-webkit-autofill:disabled,
      &:-webkit-autofill:hover:disabled,
      &:-webkit-autofill:focus:disabled {
        box-shadow: 0 0 0 30px $main-bg inset !important;
        -webkit-text-fill-color: $dark-grey !important;
        border: none !important;
      }
    }

    &.editing {
      input {
        background: black;
        padding: 15px;
        border-radius: 10px;

        &:focus,
        &:active {
          border-color: $dark-grey;
        }
      }
    }
  }

  .btn {
    all: unset;
    cursor: pointer;
    background: #e5e5e5;
    color: #3c4043;
    border-radius: 30px;
    /* font-size: 1.4rem; */
    padding: 10px 20px;
    display: inline-block;
    font-weight: 600;
    margin-top: 25px;
    transition: 0.2s all ease-in;

    &:hover {
      background: #3c4043;
      color: #e5e5e5;
    }
  }

  .profile-actions {
    list-style: none;
    font-size: 1.3rem;
    color: $light-grey;
    display: grid;
    /* grid-gap: 20px; */
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    margin-top: 60px;

    @media (min-width: $break-point) {
      grid-template-columns: 1fr;
    }

    .router-link-exact-active {
      /* background: #16171b; */
      border-right: 3px solid turquoise;
      color: turquoise;
      fill: turquoise;
    }

    .profile-action {
      cursor: pointer;
      transition: 0.2s all ease;
      display: inline-flex;
      align-items: center;
      /* width: fit-content; */
      padding: 15px 4vw;
      width: 100%;

      &:hover {
        color: $dark-grey;
        background: #333;
      }

      svg {
        width: 20px;
        fill: $dark-grey;
        margin-right: 10px;
      }

      @media (min-width: $break-point) {
        padding: 30px 4vw;
      }
    }

    .danger {
      color: $red;

      svg {
        fill: $red;
      }

      &:hover {
        color: $dark-red;
      }
    }
  }
}

.main-content {
  padding: 50px 4vw;
  background: #16171b;
  /* max-width: 100vw; */
}

.invalidPhoneNumber {
  margin-top: 5px;
}

/deep/ .loading {
  height: 100vh;
  width: 100%;
}
</style>
